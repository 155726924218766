<template>
  <b-modal :id="`modal-lg-${aNews.id}`" centered size="lg" no-close-on-backdrop>
    <template #modal-header="{}">
      <h4>{{ aNews.news_title }}</h4>

      <span v-if="aNews && aNews.updatedAt"
        >อัพเดตล่าสุด:
        {{ $_formatDateMixin_formatShort(aNews.updatedAt) }}</span
      >
    </template>

    <img class="w-100 p-3" :src="aNews.img_url !== '' ? aNews.img_url : aNews.keyImg" alt="" />
    <p class="p-3" v-html="aNews.description"></p>
    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="primary" @click="cancel"> ปิด </b-button>
    </template>
  </b-modal>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";

export default {
  mixins: [formatDateMixin],

  props: {
    aNews: Object,
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal-footer {
  justify-content: center;
}
</style>
