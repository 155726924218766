<template>
  <div class="row mb-4">
    <div class="col-12">
      <VueSlickCarousel v-if="news.length > 0" v-bind="settings" class="my-2">
        <div v-for="aNews in news" :key="aNews.id" class="max-w-320">
          <b-card
            :id="`news_${aNews.id}`"
            img-alt="Image"
            tag="article"
            class="card__news h-550"
          >
            <div :style="getStyle(aNews)"></div>

            <b-card-body class="p-3">
              <b-card-title :title="aNews.news_title"></b-card-title>

              <small
                >อัพเดตล่าสุด:
                {{ $_formatDateMixin_formatShort(aNews.updatedAt) }}</small
              >

              <b-card-text
                class="text"
              >{{ stripHtml(aNews.description) }}</b-card-text>

              <b-button
                variant="primary"
                size="sm"
                class="w-40"
                @click="$bvModal.show(`modal-lg-${aNews.id}`)"
                >อ่านต่อ</b-button
              >
            </b-card-body>
          </b-card>

          <read-news :aNews="aNews"></read-news>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import VueSlickCarousel from "vue-slick-carousel";
import { News, TeachingDare } from "../../models";
import ReadNews from "../modal/ReadNews";
export default {
  mixins: [formatDateMixin],

  components: {
    VueSlickCarousel,
    ReadNews,
  },

  data() {
    return {
      news: [],
      settings: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 1660,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1370,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },

  methods: {
    async fetchNews() {
      let { response } = await News.api().findAll();

      if (response && response.data) {
        const { data } = response.data;
        let getImgData = await this.getImgUrl(data);

        this.news = getImgData;
      }
    },

    validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    },

    async getImgUrl(data) {
      if (data) {
        const imgUrlPromise = Promise.all(
          data.map(async (item) => {
            if (!this.validURL(item.keyImg)) {
              let imgPromise = await TeachingDare.api().getImgUrl({
                key: item.keyImg,
              });

              if (imgPromise && imgPromise.response) {
                const { url } = imgPromise.response.data; // url, status

                if (url) {
                  return {
                    ...item,
                    img_url: url,
                  };
                }
              }
            }
            return { ...item, img_url: "" };
          })
        );
        return imgUrlPromise;
      }
    },

    stripHtml(html) {
      let tmp = document.createElement("DIV");

      tmp.innerHTML = html;

      return tmp.textContent || tmp.innerText || "";
    },

    getStyle(image) {
      return {
        height: "320px",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        backgroundImage: `url('${image.img_url !== "" ? image.img_url : image.keyImg ? image.keyImg : image.raw}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      };
    },
  },

  async created() {
    await this.fetchNews();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .slick-prev:before {
  content: "<";
}

/deep/ .slick-next:before {
  content: ">";
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-w-320 {
  max-width: 320px;
}
.h-550 {
  height: 550px !important;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
}
.w-40 {
  width: 40% !important;
}
</style>
