<template>
  <b-col class="banner__home mt-3">
    <b-row>
      <b-col cols="12" class="mb-3 px-0">
        <b-carousel
          v-if="images"
          id="carousel-1"
          class="carousel__banner-home"
          :interval="4000"
          controls
          indicators
          background="transparent"
          img-width="820"
          img-height="312"
        >
          <!-- Text slides with image -->
          <b-carousel-slide
            v-for="(image, index) in images"
            :key="`teaching-image-${index}`"
          >
            <template #img>
              <div
                slot="img"
                class="d-block w-100 banner__home"
                :style="getStyle(image)"
              ></div>
              <!-- <b-img
                class="d-block banner__home"
                :src="image.keyImg"
                fluid-grow
              /> -->
            </template>
          </b-carousel-slide>
        </b-carousel>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { Banner, TeachingDare } from "../../models";

export default {
  props: {
    image: Object,
    removable: {
      type: Boolean,
      default: true,
    },
  },

  model: {
    prop: "image",
    event: "change",
  },

  components: {},

  data() {
    return {
      images: null,
      showFullScreen: false,
      fullScreenImage: null,

      slickOptions: {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: false,
      },
    };
  },

  watch: {},

  computed: {},

  methods: {
    async fetch() {
      const { response } = await Banner.api().findAll();
      if (response.data) {
        const { data } = response.data;
        let getImgData = await this.getImgUrl(data);
        
        this.images = getImgData;
      }
    },

    validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    },

    async getImgUrl(data) {
      if (data) {
        const imgUrlPromise = Promise.all(
          data.map(async (item) => {
            if (!this.validURL(item.keyImg)) {
              let imgPromise = await TeachingDare.api().getImgUrl({
                key: item.keyImg,
              });

              if (imgPromise && imgPromise.response) {
                const { url } = imgPromise.response.data; // url, status

                if (url) {
                  return {
                    ...item,
                    img_url: url,
                  };
                }
              }
            }
            return { ...item, img_url: "" };
          })
        );
        return imgUrlPromise;
      }
    },

    openFullScreen(image) {
      this.fullScreenImage = image;
      this.showFullScreen = true;
    },

    onHidden() {
      this.fullScreenImage = null;
    },

    getStyle(image) {
      return {
        backgroundImage: `url('${image.img_url !== "" ? image.img_url : image.keyImg ? image.keyImg : image.raw}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      };
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.banner__home {
  border-radius: 15px;
  height: 560px;
  max-height: 560px;
}

.carousel__banner-home {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

@media (max-width: 1024px) {
  .banner__home {
    height: 380px !important;
    max-height: 380px !important;
  }

  .carousel__banner-home {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .banner__home {
    height: 360px !important;
    max-height: 360px !important;
  }

  .carousel__banner-home {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 425px) {
  .banner__home {
    height: 200px !important;
    max-height: 200px !important;
  }

  .carousel__banner-home {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
