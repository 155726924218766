<template>
  <b-modal
    id="announcement-modal"
    v-model="showModal"
    centered
    scrollable
    size="lg"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">ประกาศ</h3>
    </template>

    <announcement-carousel
      :announceList="announce_data"
    ></announcement-carousel>

    <!-- <img class="w-100 p-3" :src="aNews.keyImg" alt="" />
    <p class="p-3" v-html="aNews.description"></p> -->

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        @click="cancel()"
      >
        ปิด
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import { Auth, Announcement, TeachingDare } from "../../models";
import AnnouncementCarousel from "../carousel/Announcement.vue";

export default {
  mixins: [formatDateMixin],

  components: {
    AnnouncementCarousel,
  },

  props: {
    aNews: Object,
  },

  data() {
    return {
      showModal: false,
			announce_data: [],
    };
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

	methods: {
		async fetch() {
      const { response } = await Announcement.api().findAll();
      if (response.data) {
        const { data } = response.data;
        let showData = data.filter((item) => item.is_active == true);

				if (showData.length > 0) {
          let getImgData = await this.getImgUrl(showData);

          this.announce_data = getImgData;
					this.showModal = true;
				}
      }
    },

    async getImgUrl(data) {
      if (data) {
        const imgUrlPromise = Promise.all(
          data.map(async (item) => {
            if (item.key_img) {
              let imgPromise = await TeachingDare.api().getImgUrl({
                key: item.key_img,
              });

              if (imgPromise && imgPromise.response) {
                const { url } = imgPromise.response.data; // url, status

                if (url) {
                  return {
                    ...item,
                    img_url: url,
                  };
                }
              }
            }
            return { ...item, img_url: "" };
          })
        );
        return imgUrlPromise;
      }
    },
	},

  created() {
    this.fetch();
  },
};
</script>
