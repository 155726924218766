<template>
  <b-row class="row-content" v-if="!!guidelineData.length">
    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="8">
          <h5 class="mb-0">{{ guidelineData[0].guideline_withdraw_title }}</h5>
        </b-col>
        <b-col cols="4" class="text-right">
          <b-button variant="primary" @click.prevent="download"
            >ดาวน์โหลด</b-button
          >
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import { GuidelineWithdraw, TeachingDare } from "../../models";

export default {
  mixins: [formatDateMixin],

  data() {
    return {
      guidelineData: {},
    }
  },

  methods: {
    async fetchGuidelineWithdraw() {
      let data = [],
        additionalParams = {}

      if (this.sortBy) {
        this.$set(additionalParams, "order", [
          this.sortBy,
          this.sortDesc ? "DESC" : "ASC",
        ]);
      }

      try {
        const promise = await GuidelineWithdraw.api().findAll(
          {
            ...additionalParams
          },
          { save: false }
        );

        let { data: responseData = [] } =
          promise.response.data;

        responseData = await this.getImgUrl(responseData);

        data = responseData.map((record) => {
          return {
            ...record,
            updatedAt: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            createdAt: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
          };
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถดึงคู่มือแนะนำรายงานการขอเบิกได้ กรุณาลองใหม่อีกครั้ง");
      }

      this.guidelineData = data;
    },

    validURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    },

    async getImgUrl(data) {
      if (data) {
        const pdfUrlPromise = Promise.all(
          data.map(async (item) => {
            if (!this.validURL(item.key_img)) {
              let pdfPromise = await TeachingDare.api().getImgUrl({
                key: item.key_img,
              });

              if (pdfPromise && pdfPromise.response) {
                const { url } = pdfPromise.response.data; // url, status

                if (url) {
                  return {
                    ...item,
                    pdf_url: url,
                  };
                }
              }
            }
            return { ...item, pdf_url: "" };
          })
        );
        return pdfUrlPromise;
      }
    },

    download() {
      const link = document.createElement('a');
      link.href = this.guidelineData[0].pdf_url;
      link.target = '_blank';
      link.download = 'my-pdf-file.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },

  mounted() {
    this.fetchGuidelineWithdraw();
  },
}
</script>

<style lang="scss" scoped>
.row-content {
  margin: 16px 0;
  padding: 12px 0;
  border-radius: 16px;
	border: 0.2rem solid #3d6b99;

  /* --angle: 0deg;
  width: 100%;
	height: 100%;
	border: 0.5rem solid;
	border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
	
	animation: 3s rotate linear infinite; */
}

@keyframes rotate {
	to {
		--angle: 360deg;
	}
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}
</style>