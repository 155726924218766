<template>
  <div class="row mb-4">
    <div class="col-12 px-0">
      <VueSlickCarousel
        v-if="videos.length > 0"
        v-bind="settings"
        class="my-2"
        style="max-width: 1500px"
      >
        <div v-for="video in videos" :key="video.id" class="max-w-320">
          <a class="card-img-wrapper rounded" href="javascript:void(0)">
            <b-embed
              v-if="validateYouTubeUrl(video.keyVdo)"
              :src="video.keyVdo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            >
            </b-embed>

            <b-embed v-else type="video" controls>
              <source :src="video.keyVdo" type="video/mp4" />
            </b-embed>
          </a>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import VueSlickCarousel from "vue-slick-carousel";
import { Video } from "../../models/Video";
export default {
  mixins: [formatDateMixin],

  components: {
    VueSlickCarousel,
  },

  data() {
    return {
      videos: [],
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: true,
        responsive: [
          // {
          //   breakpoint: 1660,
          //   settings: {
          //     slidesToShow: 2,
          //     slidesToScroll: 1,
          //   },
          // },
          // {
          //   breakpoint: 1419,
          //   settings: {
          //     slidesToShow: 2,
          //     slidesToScroll: 1,
          //   },
          // },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },

  methods: {
    async fetchVideos() {
      let { response } = await Video.api().findAll();

      if (response && response.data) {
        const { data } = response.data;
        this.videos = data;
      }
    },

    validateYouTubeUrl(src) {
      if (src != undefined || src != "") {
        var regExp =
          /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (src.match(regExp)) {
          return true;
        } else {
          return false;
        }
      }
    },

    stripHtml(html) {
      let tmp = document.createElement("DIV");

      tmp.innerHTML = html;

      return tmp.textContent || tmp.innerText || "";
    },
  },

  async created() {
    await this.fetchVideos();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .slick-prev:before {
  content: "<";
}

/deep/ .slick-next:before {
  content: ">";
}

/deep/ .slick-slide {
  padding-left: 1rem;
  padding-right: 1rem;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  @media (max-width: 760px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-w-320 {
  max-width: 830px;
}
.h-550 {
  height: 550px !important;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.w-40 {
  width: 40% !important;
}
</style>
