<template>
  <div class="row mb-4 mx-0">
    <div class="col-12 wrap-content">
      <VueSlickCarousel v-if="announceList.length > 0" v-bind="settings" class="my-2 wrap-carousel">
        <div v-for="item in announceList" :key="item.id">
          <img
            v-if="item.img_url"
            class="w-100 mb-3 announce-img"
            :src="item.img_url"
            :alt="item.id"
          />
          <p v-html="item.announcement_title"></p>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  mixins: [formatDateMixin],

  components: {
    VueSlickCarousel,
  },

  props: {
    announceList: {
      type: Array,
      default: () => ([]),
    }
  },

  data() {
    return {
      news: [],
      settings: {
        dots: false,
        // edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    };
  },

  methods: {
    stripHtml(html) {
      let tmp = document.createElement("DIV");

      tmp.innerHTML = html;

      return tmp.textContent || tmp.innerText || "";
    },

    getStyle(image) {
      return {
        height: "320px",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        backgroundImage: `url('${image.keyImg}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .slick-prev,
/deep/ .slick-next {
  margin-top: 0;
}
/deep/ .slick-prev {
  margin-left: -20px;
}
/deep/ .slick-next {
  margin-right: -20px;
}
/deep/ .slick-prev:before {
  content: "<";
}

/deep/ .slick-next:before {
  content: ">";
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-w-320 {
  max-width: 320px;
}
.h-550 {
  height: 550px !important;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
}
.w-40 {
  width: 40% !important;
}
.wrap-carousel {
  width: 95%;
  /* min-height: 45dvh; */
}
.announce-img {
  height: 240px;
  object-fit: cover;
  border-radius: 10px;
}
</style>
