<template>
  <page-content>
    <page-title
      heading="ยินดีต้อนรับเข้าสู่เว็บไซต์ D.A.R.E. Thailand"
    ></page-title>

    <guideline-content></guideline-content>

    <banner-image-home></banner-image-home>

    <b-container class="container___width-override-video">
      <video-home></video-home>
    </b-container>

    <h3 class="mt-4">ข่าวประชาสัมพันธ์</h3>

    <b-container class="container___width-override">
      <news></news>
    </b-container>

    <read-announcement></read-announcement>
  </page-content>
</template>

<script>
import PageContent from "../components/layout/PageContent";
import PageTitle from "../components/layout/PageTitle";
import BannerImageHome from "../components/carousel/BannerImageHome";
import VideoHome from "../components/carousel/VideoHome";
import News from "../components/carousel/News";
import ReadAnnouncement from "../components/modal/ReadAnnouncement.vue";
import GuidelineContent from "../components/layout/GuidelineContent";

export default {
  components: {
    PageContent,
    PageTitle,
    BannerImageHome,
    News,
    VideoHome,
    ReadAnnouncement,
    GuidelineContent,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 77vw !important;

    @media only screen and (max-width: 1660px) and (min-width: 1370px) {
      max-width: 71vw !important;
    }
    @media only screen and (max-width: 1369px) and (min-width: 1140px) {
      max-width: 67vw !important;
    }

    @media only screen and (max-width: 1139px) and (min-width: 992px) {
      max-width: 61vw !important;
    }

    @media only screen and (max-width: 991px) and (min-width: 760px) {
      max-width: 87vw !important;
    }

    @media (max-width: 759px) {
      max-width: 73vw !important;
    }
  }

  &___width-override-video {
    max-width: 77vw !important;

    @media only screen and (max-width: 1660px) and (min-width: 1370px) {
      max-width: 71vw !important;
    }
    @media only screen and (max-width: 1369px) and (min-width: 1140px) {
      max-width: 67vw !important;
    }

    @media only screen and (max-width: 1139px) and (min-width: 992px) {
      max-width: 61vw !important;
    }

    @media only screen and (max-width: 991px) and (min-width: 760px) {
      max-width: 87vw !important;
    }

    @media (max-width: 759px) {
      max-width: 73vw !important;
    }
  }
}
</style>
